import React from 'react';
import { PageTitle } from '../../../components';
import { Team } from '../../../sections';


const TeamPage = () => {
  return (
    <>
      <PageTitle title={'team'} page={'team'} />
      <Team />
    </>
  )
}

export default TeamPage;